import _ from 'lodash';
window._ = _;

import $ from 'jquery';
window.jQuery = window.$ = $
window.jQuery.event.global = {};

import * as Vue from 'vue/dist/vue.esm-bundler';
window.Vue = Vue;

import { useEchoServer } from "./echo_server";
window.useEchoServer = useEchoServer;

import Cookies from 'js-cookie';
window.Cookies = Cookies;

import * as Ladda from './vendor/ladda/ladda';
window.Ladda = Ladda;

import * as bodyScrollLock from 'body-scroll-lock';
window.bodyScrollLock = bodyScrollLock;

import axios from 'axios';
axios.defaults.withCredentials = true;
window.axios = axios;

import Pusher from 'pusher-js';
if (!import.meta.env.PROD) {
    // 本番環境以外は、ログ出力をする
    Pusher.logToConsole = true;
}
window.Pusher = Pusher;

import Echo from 'laravel-echo';
window.LaravelEcho = Echo;


window.entranceMixin = {
    data() {
        return {
            headerHeight: 0,
        };
    },
    mounted() {
        // console.log('entrance mixin');
        // window.addEventListener('resize', this.onResize);
        // this.onResize();
    },
    beforeDestroy() {
        // window.removeEventListener('resize', this.onResize);
    },
    methods: {
        // onResize() {
        //     let height=$('#header').height();
        //     this.headerHeight = height;
        //
        //     console.log(height);
        //
        //     $('#contents').css("margin-top", height);
        // },
        voiceClick: function voiceClick(e) {
            // if (!this.isPlaySound) {
            //     this.showDialog('音声が再生できません', "音声／サウンドがOFFのため再生できません。\n" + "音声を聞くには「マイページ」の「音声／サウンド」をONにする必要があります。");
            //     return;
            // }

            let target = $(e.currentTarget),
                dom = target.children('img');

            let selectVoice = target.next('audio').get(0);

            if (selectVoice.paused) {
                if (this.playingVoice) {
                    this.selectVoiceDom.attr('src', '/images/btn/voice_play.png');
                    this.playingVoice.pause();
                }

                dom.attr('src', '/images/btn/voice_playing.png');
                selectVoice.currentTime = 0;
                selectVoice.play();

                selectVoice.addEventListener('ended', function () {
                    dom.attr('src', '/images/btn/voice_play.png');
                });

                this.selectVoiceDom = dom;
                this.playingVoice = selectVoice;
            } else {
                this.playingVoice = null;
                selectVoice.pause();
                dom.attr('src', '/images/btn/voice_play.png');
            }
            return false;
        },
        _attachSampleVoice: function _attachSampleVoice() {
            $(document).on('click', '.sample_voice', this.voiceClick);
        },

        dialog_initOpen: function dialog_initOpen(target) {
            this.$nextTick(function () {
                $('html').css('overflow', 'inherit');
                bodyScrollLock.disableBodyScroll($(target)[0], {
                    reserveScrollBarGap: true,
                    allowTouchMove: function allowTouchMove(el) {
                        if (el.tagName === 'TEXTAREA' && el.scrollHeight > el.clientHeight) return true;
                        while (el && el !== document.body) {
                            if (el.getAttribute('data-scroll') !== null) {
                                if (el.scrollHeight > el.clientHeight) return true;
                                break;
                            }
                            el = el.parentElement;
                        }
                    }
                });
            });
        },
        dialog_initClose: function dialog_initClose() {
            $('html').css('overflow', '');
            $('html').css('scroll-behavior', 'auto');
            bodyScrollLock.clearAllBodyScrollLocks();
            this.$nextTick(function () {
                $('html').css('scroll-behavior', '');
            });
        },
    }
};
